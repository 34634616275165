/* istanbul ignore file: todo(eran): not running image tests on this file yet */

import React from 'react';
import classNames from 'classnames';
import {ImageUiTpaWrapper} from './ImageUiTpaWrapper';
import autobind from 'auto-bind-es5';
import s from './ProductImage.scss';
import {HoverType, ImageModeId, ImageRatioId, IProduct, ProductType} from '../../../../types/galleryTypes';
import {ImageResizeOptions} from 'wix-ui-tpa';
import * as Icons from '@wix/wixstores-client-common-components/dist/src/icons/dist';

export interface IProductImageProps {
  product: IProduct;
  isMobile: boolean;
  hoverType: string;
  imageRatioId: number;
  imageModeId: number;
  classNames: {thumbnail: string; image: string};
  children?: React.ReactChild[] | React.ReactChild;
  textsMap: {
    digitalProductBadgeAriaLabel: string;
  };
  isAllowGalleryRoundCornersInViewer: boolean;
}

interface IProductImageState {
  imageContainerDimensions: {width: number; height: number};
}

export enum DataHook {
  Images = 'product-item-images',
  PrimaryImage = 'product-item-primary-image',
  SecondaryImage = 'product-item-secondary-image',
  Badge = 'product-badge',
}

export const ratioIdToRatioMap = {
  [ImageRatioId._3x2]: 3 / 2,
  [ImageRatioId._4x3]: 4 / 3,
  [ImageRatioId._1x1]: 1,
  [ImageRatioId._3x4]: 3 / 4,
  [ImageRatioId._2x3]: 2 / 3,
  [ImageRatioId._16x9]: 16 / 9,
  [ImageRatioId._9x16]: 9 / 16,
};

export class ProductImageComponent extends React.Component<IProductImageProps, IProductImageState> {
  constructor(props) {
    super(props);
    autobind(this);
  }

  private readonly imageContainerRef = React.createRef<HTMLDivElement>();

  public renderDigitalBadge = () => {
    return (
      <div
        className={s.productBadge}
        data-hook={DataHook.Badge}
        aria-label={this.props.textsMap.digitalProductBadgeAriaLabel}>
        <Icons.DigitalProductBadge />
      </div>
    );
  };

  public render() {
    const {imageRatioId, isAllowGalleryRoundCornersInViewer, product} = this.props;

    return (
      <div
        className={classNames(s.productImages, {[s.roundCorners]: isAllowGalleryRoundCornersInViewer})}
        style={{aspectRatio: ratioIdToRatioMap[imageRatioId].toString()}}
        ref={this.imageContainerRef}
        data-hook={DataHook.Images}>
        {this.renderImages()}
        {product.productType === ProductType.DIGITAL && this.renderDigitalBadge()}
        {this.props.children}
      </div>
    );
  }

  private renderImages() {
    const {
      hoverType,
      imageModeId,
      isMobile,
      product,
      classNames: {thumbnail: externalThumbnailClass, image: externalImageClass},
    } = this.props;

    const shouldRenderSecondaryImage = !isMobile && product.media.length >= 2 && hoverType === HoverType.Alternate;
    const imageClassNames = classNames(s.productImage, externalImageClass);
    const thumbnailClass = classNames(externalThumbnailClass);

    return (
      <>
        <ImageUiTpaWrapper
          mediaItemIndex={0}
          className={thumbnailClass}
          imageClassName={imageClassNames}
          product={product}
          data-hook={DataHook.PrimaryImage}
          resize={imageModeId === ImageModeId.Crop ? ImageResizeOptions.cover : ImageResizeOptions.contain}
          forceAspectRatio={ratioIdToRatioMap[this.props.imageRatioId]}
        />
        {shouldRenderSecondaryImage && (
          <ImageUiTpaWrapper
            mediaItemIndex={1}
            className={thumbnailClass}
            imageClassName={imageClassNames}
            product={product}
            data-hook={DataHook.SecondaryImage}
            resize={imageModeId === ImageModeId.Crop ? ImageResizeOptions.cover : ImageResizeOptions.contain}
            forceAspectRatio={ratioIdToRatioMap[this.props.imageRatioId]}
          />
        )}
      </>
    );
  }
}

export const ProductMedia = ProductImageComponent;
