/* istanbul ignore file: todo(eran): not running image tests on this file yet */

import React from 'react';
import classNames from 'classnames';
import {Image, ImageLoadingBehaviorOptions, ImageResizeOptions} from 'wix-ui-tpa';
import * as Icons from '@wix/wixstores-client-common-components/dist/src/icons/dist';
import {ProductType, MediaFrameMediaType} from '../../../../types/galleryTypes';
import s from './ImageUiTpaWrapper.scss';

export enum DataHook {
  Wrapper = 'product-thumbnail-wrapper',
  Media = 'product-thumbnail-media',
  DefaultImage = 'default-image',
}

export interface ImageUiTpaWrapperProps {
  product: any;
  className?: string;
  imageClassName?: string;
  mediaItemIndex?: number;
  resize?: ImageResizeOptions;
  forceAspectRatio: number;
}

const defaultIconSize = '50';

const iconByDigitalFileType = (product) => {
  const fileType = product?.digitalProductFileItems[0]?.fileType;

  const icons = {
    [MediaFrameMediaType.SECURE_DOCUMENT]: <Icons.DocumentDigitalProductDefaultImage size={defaultIconSize} />,
    [MediaFrameMediaType.SECURE_PICTURE]: <Icons.PictureDigitalProductDefaultImage size={defaultIconSize} />,
    [MediaFrameMediaType.SECURE_VIDEO]: <Icons.VideoDigitalProductDefaultImage size={defaultIconSize} />,
    [MediaFrameMediaType.SECURE_MUSIC]: <Icons.MusicDigitalProductDefaultImage size={defaultIconSize} />,
    [MediaFrameMediaType.SECURE_ARCHIVE]: <Icons.DocumentDigitalProductDefaultImage size={defaultIconSize} />,
  };

  return icons[fileType] || icons[MediaFrameMediaType.SECURE_DOCUMENT];
};

const isDigitalProduct = (product) => product.productType === ProductType.DIGITAL;

const defaultImage = (product) => {
  if (isDigitalProduct(product)) {
    return iconByDigitalFileType(product);
  } else {
    return <Icons.PhysicalProductDefaultImage size={defaultIconSize} />;
  }
};

export const ImageUiTpaWrapper = ({
  product,
  className,
  mediaItemIndex,
  imageClassName,
  resize,
  forceAspectRatio,
}: ImageUiTpaWrapperProps) => {
  const mediaItem = product.media.length > mediaItemIndex && product.media[mediaItemIndex];
  const alt = product.media.altText || product.name;

  return mediaItem ? (
    <div className={classNames(className)} data-hook={DataHook.Wrapper}>
      <Image
        data-hook={DataHook.Media}
        src={mediaItem.url}
        width={mediaItem.width}
        height={mediaItem.height}
        alt={alt}
        className={imageClassName}
        fluid
        loadingBehavior={ImageLoadingBehaviorOptions.blur}
        aspectRatio={forceAspectRatio}
        resize={resize}
      />
    </div>
  ) : (
    <div
      data-hook={DataHook.DefaultImage}
      className={classNames(s.defaultImage, className)}
      style={{width: mediaItem.width, height: mediaItem.height}}>
      {defaultImage(product)}
    </div>
  );
};
