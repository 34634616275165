import React from 'react';
import s from './Ribbon.scss';
import {DataHook} from '../ProductItem';
import {RibbonPlacementId} from '../../../../types/galleryTypes';

export interface IRibbonProps {
  ribbon: string;
  placement: RibbonPlacementId;
}

export class Ribbon extends React.Component<IRibbonProps> {
  public render() {
    const {ribbon, placement} = this.props;
    return (
      <span
        className={placement === RibbonPlacementId.OnImage ? s.ribbonOnImage : s.ribbonProductInfo}
        data-hook={DataHook.RibbonNew}>
        {ribbon}
      </span>
    );
  }
}
